@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&display=swap');
* {
}
body{
    /* background-image: url('../assets/images/bg1.png'); */
    color: #333;
    height: 100%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 100px) {
    body {
        background-image: url('../assets/images/mobilebg.png');
    }
}

@media (min-width: 600px) {
    body {
        background-image: url('../assets/images/mobilemed.png');
    }
}

@media (min-width: 700px) {
    body {
        background-image: url('../assets/images/bg1.png');
    }
}

.App {
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.clock {
    position: relative;
    margin: a;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
    width: 350px;
    font-family: Lobster Two;
    margin: 0;
    padding: 0 ;
}
.clock section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin: .3rem;
    padding: 0 ;
}
.clock section p{
    font-size: 4rem;
    margin: 0;
    padding: 0 ;
}
.clock section small{
    font-size: 1.2rem;
}
.clock span{
    font-size: 1.5rem;
}
.main-container {
    font-family: 'Jost', sans-serif;
}
.main-container h4{
    font-family: 'Jost', sans-serif;
    font-size: 110%;
    font-style: thin;
    margin: 20% 0 0 0;
    padding: 0 ;
}
.main-container h1{
    font-family: Lobster Two;
    font-size: 3em;
    margin: 0;
    padding: 0 ;
}